import React from 'reactn';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import backgroundImage from 'assets/backgrounds/art_not_war.gif';
import { Button, Panel } from 'react95';

export default function ArtNotWar({ onClose }) {

  return (
    <ThemeProvider theme={original}>
      <ADiv>
        <Panel
        className='panel'
          variant="well"
          style={{
            
            height: '100%',
            width:'100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
         <div className='titleitem'>'QKABOOM: Art Not War' XPERIENCE CONSOLE
          <br/>
          <br/>
          <span className='collab'>A collab xperience with Hackatao</span></div>
           <a href="https://nyc3.digitaloceanspaces.com/virtualspace/Downloads/ArtNotWar/XPERIENCE_QKABOOM.zip" target="_blank">
            <Button
              className="connectButton"
            >
              Download for Windows
            </Button>
          </a> 
         {/*  <a href="#" target="_blank">
            <Button
              className="connectButton"
            >
              Awaiting Signal X II
            </Button>
          </a> */}
          {/* <a href="https://nyc3.digitaloceanspaces.com/virtualspace/Downloads/ArtNotWar/ArtNotWar_Mac.zip" target="_blank">
            <Button
              className="connectButton"
            >
              Download Mac Version
            </Button>
          </a> */}
        </Panel>
      </ADiv>
    </ThemeProvider>
  );
}

const ADiv = styled.div`
  margin-top:3px;
  height: 100%;

    .panel{
      flex-direction: column;
      display: flex;
      align-items: center;
      padding-left:10px;
      padding-right:10px;
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      
      >.titleitem{
        color: black;
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        margin: 30px;
      }
      
      >.titleitem>.collab{
        font-size:19px;
      }

      > a {
        text-decoration: none;
      }
      
    }
    .connectButton {
      width: 270px;
      font-size: 18px;
      height: 49px;
      margin: 25px;
      margin-top: 50px;
    }
`;
