import styled from 'styled-components';
import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';

import axios from 'axios';
import TokenImage from './TokenImage';
import MultiverseVirtualMachine from 'artifacts/contracts/MultiverseVirtualMachine.sol/MultiverseVirtualMachine.json';

import { ethers } from 'ethers';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import {
  autourl,MINT_PRICE_DEFAULT,
  BASE_URI,
  ONE_TOKEN,
  TOKEN_GALLERY,
  INTERACTIVE,
  MINT_TOKEN,
  MY_TOKENS,
  USER_TOKENS,
  CONTRACT_ADDR,
  MAX_TOKEN,
  PREVIEW_ANIM,
  correctChain,StillMintable
} from 'lib/lib.js';

import {
  styleReset,
  Button,
  TextField,
  WindowHeader,
  WindowContent,
  List,
  ListItem,
  Divider,
  Cutout,
  Toolbar,
  Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeadCell,
  TableDataCell,
  Window,
} from 'react95';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }

  ${styleReset}
`;
const MintToken = ({ allTokens, viewTokenFn, viewInteractiveFn ,rollrandom}) => {
  //load the token data here

  const [dappState, setDappState] = useGlobal();
  const provider = dappState.provider;
  const contract = dappState.contract;
  const address = dappState.address;

  //ui elements

  const BUYSTATE_NORMAL = 'NORMAL';
  const BUYSTATE_MINTING = 'MINTING';
  const BUYSTATE_PAYING = 'PAYING';

  const [preview, setPreview] = useState(PREVIEW_ANIM);

  const [buyamount, setBuyamount] = useState(1);

  const [price, setPrice] = useState(MINT_PRICE_DEFAULT);//will just use this first (fetch price might not be used)

  const [buyState, setBuyState] = useState(BUYSTATE_NORMAL);

  console.log('dapp state', dappState);

  const [correctNetwork, SetCorrectNetwork] = useState(true);
  //so fetch the attributes now?

  //contract calls

  //#region contract calls

  async function fetchPrice() {
    if (typeof window.ethereum !== 'undefined') {
      console.log('fetching price');

      try {
        const data = Number(ethers.utils.formatEther(await contract.price()));
        console.log('price: ', data);

        setPrice(data);

        return data;
      } catch (err) {
        console.log('token URI Error: ', err);
      }
    } else {
      console.log('ethereum api not detected');
      return '';
    }
  }
  function requestAccount() {
    window.ethereum.request({ method: 'eth_requestAccounts' }, addr => {
      console.log('eth requested account success');
      console.log('returned address:', addr);
    });
  }

  async function purchase() {

    if (typeof window.ethereum !== 'undefined' && provider != null) {
      setBuyState(BUYSTATE_PAYING);

      await requestAccount();
      //see if provider is there
      console.log({ provider });
      const signer = provider.getSigner();

      const writecontract = new ethers.Contract(
        CONTRACT_ADDR,
        MultiverseVirtualMachine.abi,
        signer,
      );

      let purchasesum = ethers.utils.parseEther((price * buyamount).toString());

      try {
        console.log(price * buyamount);
        const tx = await writecontract.purchase(buyamount, {
          value: purchasesum,
        });

        console.log('await 1 complete');

        //await tx
        tx.wait(
          console.log('waiting for transaction to go complete'),
          setBuyState(BUYSTATE_MINTING),
        )
          .then(receipt => {
            console.log('receipt');
            //receipt will contains events
            let lastid = -1;

            for (let i = 0; i < receipt.events.length; i++) {
              console.log(receipt.events[0]);

              var tokenid = receipt.events[i].args['tokenId'];
              if (tokenid != null) {
                let tokenidnum = tokenid.toNumber();
                if (tokenidnum > lastid) {
                  lastid = tokenidnum;
                }
                console.log('new token ', tokenidnum);
              }
            }

            console.log('last token received ', lastid);
            //setBuyState(BUYSTATE_NORMAL);

            ViewMintedToken(lastid.toString());

          })
          .catch(err => {
            console.log('metamask error');
            console.log(err);
            setBuyState(BUYSTATE_NORMAL);

          });
      } catch (err) {
        console.log('metamask error');
        console.log(err);
        setBuyState(BUYSTATE_NORMAL);
      }
    }
  }

  //#endregion

  //#region apiCallAndSet

  //#endregion

  useEffect(() => {
    console.log('use effect triggered');

    fetchPrice().then(result => {
      console.log('use effect price is ', result);
    });
  }, []);

  //detect network changes
  useEffect(() => {
    console.log('detecting correct chain');
    if (window.ethereum != null && correctChain(dappState.chainId)) {
      SetCorrectNetwork(true);
    } else {
      SetCorrectNetwork(false);
    }
  }, [dappState.chainId]); 

  function ViewMintedToken(newtoken) {
    console.log('view new token', newtoken);
    viewTokenFn(newtoken.toString());
  }

  function setTextBuyamount(e) {
    var value = e.target.value;

    if (Number(value)) {
      if(Number(value)>200){
        value=200;
      }

      if(Number(value)<1){
        value=1;
      }
      setBuyamount(value);

//      setBuyamount(e.target.value);
    }
  }

  return (
    <Panel
      variant="outside"
      shadow
      style={{
        padding: '0.5rem',
        lineHeight: '1.5',
        display: 'block',
        justifyContent: 'center',
        minWidth: '60%',
        maxWidth: '500px',
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <Panel
          variant="well"
          style={{
            height: 200,
            width: 210,
          }}
        >
          <TokenImage url={preview}></TokenImage>
        </Panel>

        <Panel
          variant="well"
          style={{
            padding: '10px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'space-around',
            alignContent: 'center',
            flexDirection: 'column',
            width: 'calc(100% - 210px)'
          }}
        >
          <div>Uncover the mysteries of the multiverse here:</div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              placeContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              textAlign:'center'
            }}
          >
            <TextField
              placeholder={buyamount}
              onChange={setTextBuyamount}
              className="text"
              style={{
                border: 'none',
                boxShadow: 'none',
                boxSizing: 'unset',
                padding: '0px',
                margin: '1% 0',
              }}
            />
            <div style={{ marginLeft: '5px' }}>Pieces</div>
          </div>

          <Button
            onClick={purchase}
            className='adjtext'
            style={{ margin: '5px', padding: '5px' }}
            disabled={
              buyState != BUYSTATE_NORMAL ||
              StillMintable( allTokens) ||
              !correctNetwork
            }
          >
            {!correctNetwork && <span>Connect Wallet To Mint</span>}
            {correctNetwork && address == null && (
              <span>Connect Wallet To Mint</span>
            )}

            {correctNetwork && address != null && (
              <span>
                {buyState == BUYSTATE_NORMAL ? 'Mint' : 'Minting'} Your{' '}
                {buyamount == 1 ? '' : buyamount}
                {buyamount == 1 ? 'Simulation' : ' Simulations'}
              </span>
            )}
          </Button>
          
          {StillMintable( allTokens)&& <span style={{fontSize:'12px',color:'blue'}}>All tokens minted: Connect Metamask and test a random one @FeelingLucky</span>}
          {!correctNetwork && <span style={{fontSize:'12px',color:'blue'}}>Please connect To Ethereum Network</span>}
        </Panel>
      </div>

      <Panel
        variant="well"
        style={{
          textAlign: 'center',
          padding: '20px',
        }}
      >
      <img src="./img/universe-original-size.gif" alt="gif " style={{width:'100%'}} className="minttokenimg" />

      <div className='description'>
                
        You've been chosen to receive the power to <a href="#" onClick={rollrandom}>bring your NFTs into the multiverse</a>, experience dynamic NFTs. (PORTAL Connectors Online: <a href="#" onClick={rollrandom}>ＬⓄ𝐨丅, t𝓔𝓜ρ𝔬яＡㄥ 𝓵Ø𝓸Ｔ, 𝔰уภtнᵉŤ𝓲Ⓒ ᒪ𝓞όt</a>), created by
        <br/>
        <br/>
         ░█▀▀█ ░█─░█ ─█▀▀█ ░█▀▀█ ░█▀▀▄ ▀█▀ ─█▀▀█ ░█▄─░█ ░█▀▀▀█ 
░█─▄▄ ░█─░█ ░█▄▄█ ░█▄▄▀ ░█─░█ ░█─ ░█▄▄█ ░█░█░█ ─▀▀▀▄▄ 
░█▄▄█ ─▀▄▄▀ ░█─░█ ░█─░█ ░█▄▄▀ ▄█▄ ░█─░█ ░█──▀█ ░█▄▄▄█
        <br/>
        <br/>
        A unique dynamic Multiverse VM Token design, each and every Multiverse VM Planet is able to link to other planets via links generated upon the creation of multiverse vm, and additional links are generated via a combination of the traveller's address and the token seed which updates dynamically upon token transfers.
      </div>
      </Panel>
    </Panel>
  );
};

export default MintToken;
