
let ipfsPrefix = 'ipfs://';
let httpPrefix = 'https://ipfs.io/ipfs/';

export var MORE_URL = 'https://www.xenoinfinity.com/';


/*
uribasein:"https://localhost:5000/Outputwrite/"
maxusertokens:8000
mversetokens:80
maxlinkcountin:16
mintprice:50000000000000000
initpause:false
*/

//this version is mainly used locally 
export const INTERACTIVE_URI = 'nftinteract/index.html';
export const INTERACTIVE_TOKEN_URI = 'nftinteract/index.html?tokenseed=';

//testhttps://api.multiversevm.com/nftinteract/index.html?tokenseed=1
//export const INTERACTIVE_TOKEN_URI = 'https://api.multiversevm.com/nftinteract/index.html?tokenseed=';


export const INTERACTIVE_TOKEN_URI_EXTRA = 'extranftinteract/index.html?tokenseed=';

export const LoginMessage = 'multiversevmlogin';


  //liveswap
 export let computerurl='computer.html';
 export let portalurl='computer/portal.html';

/* export const BASE_URI='http://localhost:5000/token/';
export const ANIMATION_BASE_URI='ipfs:///';
 */

//liveswap
//export const BASE_URI = 'http://localhost:5000/token/'; //localhost
//tokens, not used though
//export const BASE_URI ='https://ipfs.io/ipfs//';
export const BASE_URI ='https://api.multiversevm.com/';

//animations
//export var ANIMATION_BASE_URI = 'https://ipfs.io/ipfs//';

export var ANIMATION_BASE_URI = 'https://apidata.multiversevm.com/anim/';

export var TEMP_LOOT_ADDR = '0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00';

//liveswap

//Main Net
export var CONTRACT_ADDR = '0xD5A73977e6eFfC252A7f1AF4548BA11fF7187616';
export var SLOOT_CONTRACT_ADDR = '0x869ad3dfb0f9acb9094ba85228008981be6dbdde';
export var LOOT_CONTRACT_ADDR = '0xFF9C1b15B16263C61d017ee9F65C50e4AE0113D7';
export var MLOOT_CONTRACT_ADDR = '0x1dfe7Ca09e99d10835Bf73044a23B73Fc20623DF';

export var MINT_PRICE_DEFAULT = 0.05;//rinkeby original

//liveswap
export const tempurl = false;
export const rinkebymode=false; 
export const animfromipfs=false;

//liveswap
if(rinkebymode){//local or rinkeby
  // SLOOT_CONTRACT_ADDR = '';
  //https://ipfs.io/ipfs/
  
  //RINKEBY
  LOOT_CONTRACT_ADDR="";
  SLOOT_CONTRACT_ADDR = "";
  MLOOT_CONTRACT_ADDR ="";
  CONTRACT_ADDR = '';//rinkeby mverse tokens
  ANIMATION_BASE_URI = 'http://ipfs.io/ipfs//';
}

export const ONE_TOKEN = 'One Token';
export const TOKEN_GALLERY = 'All Simulations';
export const INTERACTIVE = 'Interactive';
export const MINT_TOKEN = 'Mint';
export const MY_TOKENS = 'My Simulations';
export const EXTRA_INTERACTIVE = 'Рⷬhͪaͣs͛eͤ 2̸̙͍̐͘͜  Rͬeͤmͫoͦᴛⷮeͤ Feͤeͤdͩ';

export const USER_TOKENS = 'User Tokens';

export const RANDOM_INTERACTIVE = 'Interactive Simulation';
export const RANDOM_TOKEN = 'Simulation Token';




export const CORRECT_NETWORK_WINDOW = 'Correct Network Window';
export const EXTERNAL_LINK = 'External Link';

export const HOME_WINDOW = 'HOME_WINDOW';

export const MAX_TOKEN = 8000;
export const MVERSE_TOKEN = 80;
export const PURCHASE_MAX= 200;

//export const INTROTIME = 11000; pre intro time 9000
// export const INTROTIME =11000;
// export const PREINTROTIME =9000;

//usually 5000
export const PRELAUNCHTIME = 10000000000;
export const PREINTROTIME = 5000;//set to 5000 once ready
//export const PREINTROTIME =0;//set to 5000 once ready
//export const PREINTROTIME =0;//set to 5000 once ready


export var INTROTIME = PREINTROTIME + 2000;

if (PREINTROTIME == 0) {
  INTROTIME = 0;
}
 

//specific for mverse

export const mverseTokensArray=[];

export const StillMintable = (alltokensin)  => {
  //remove the count of mversetokens
  //1,2,8000,8001
  //mintedlength=2

  let mintedlength=alltokensin.length-mverseTokensArray.length;
  return MAX_TOKEN <= mintedlength;
};

export const IncludeMverseTokens = async(alltokens)  => {
  alltokens.push(...mverseTokensArray);
  return alltokens;
};

export const TokenToMverseIncluded = (tokeninput)  => {
  //if input >=8000 then mverse

  if(tokeninput<MAX_TOKEN){//under 8000
    return tokeninput;
  }else{
    //looping first
    let prev=MAX_TOKEN;

    for(let i=0;i<mverseTokensArray.length;i++){
      //8000 then prev;

      
      if(mverseTokensArray[i]>tokeninput){
        return mverseTokensArray[i];//return first larger than this
      }
    }

  }

  return -1;
};


//loot contract
//mloot contract

export const autotokenuri = urlinput => {
  //console.log('is prefx', ipfsPrefix);

  return urlinput;
/* 
  if (tempurl) {
    console.log('using temp tokenuri');
  } else {
    return urlinput;
  } */
};

export const autourl = urlinput => {
  //console.log('is prefx', ipfsPrefix);
  let url = urlinput.replace(ipfsPrefix, httpPrefix);
  console.log('autourl ', url);
  return url;
};

export const animationurl = token => {

  /* 
  let index = parseInt(token) % 2;
  let indexstr = index.toString() + '.mp4'; */
  let indexstr = token+ '.mp4';
  return autourl(ANIMATION_BASE_URI + indexstr);
};

//export let PREVIEW_ANIM = animationurl("./img/preview.mp4");
export let PREVIEW_ANIM ="./img/preview.mp4";

export const getRandomInt = max => {
  return Math.floor(Math.random() * max);
};

export const correctChain = chainid => {
  if (chainid == '0x1' || chainid == '0x539'|| chainid == '0x4') {
    return true;
  } else {
    return false;
  }
};

export const chainString = chainid => {
  console.log('chain id ', chainid);
  switch (chainid) {
    case '0x1':
      return 'Ethereum Main Network';
    case '0x3':
      return 'Ropsten Test Network';
    case '0x4':
      return 'Rinkeby Test Networkk';
    case '0x5':
      return 'Goerli Test Network';
    case '0x2a':
      return 'Kovan Test Network';
    case '0x539':
      return 'Local Testnet';
    default:
      return 'Unknown Network';
  }

  return 'Unknown Network';
};

//0 static links
//1 dynamic user links
export const fetchLinks=async(contractin,tokenId, type) =>{
  let links = [];
  if (typeof window.ethereum !== 'undefined') {
    console.log('fetching token id uri');

    try {
      if (type == 0) {
        links = await contractin.GetLinks(tokenId);
      } else {
        links = await contractin.GetDynamicLinks(tokenId);
      }
      //GetDynamicLinks
      console.log('token links: ', links);
      //setGlobal({extradata:data
    } catch (err) {
      console.log('token links Error: ', err);
    }
  } else {
    console.log('ethereum api not detected');
  }

  return links.map(item => item.toNumber());
}

export const processLinkarray=(allTokens,linkarray) => {
  let formattedarray1 = [];
  for (let i = 0; i < linkarray.length; i++) {
    let item = linkarray[i];

    let mintedval=  allTokens.includes(item);
    formattedarray1.push({
      itemid: item,
      minted:  mintedval,
      classstr: mintedval ? 'minted' : 'notminted',
    });
  }
  
  return formattedarray1;
}

export const SignMessage=async (provider,message) =>{
 
  const signer = provider.getSigner();
  const signature = await signer.signMessage( message ) ;
/* 
  console.log(`signed message is: ${signature}`);
  alert(signature); */

  return signature;

}

export const AutoLoginToContinue=async (providerin,addressin,ethersin) =>{

  let returnval={};

  try{

    let localsignature =  window.localStorage.getItem('signature');
    let message=LoginMessage;

    let shouldresign=false;

    if(localsignature==null){
      shouldresign=true;
    }else{
      //check signature with message

      const localsignedaddress = ethersin.utils.verifyMessage(message,localsignature);
      
      //check if same with current addresss
      shouldresign=(addressin.toUpperCase()!=localsignedaddress.toUpperCase());
    }
    
    if(shouldresign){
      let signature=await SignMessage(providerin,message);
      window.localStorage.setItem('signature', signature);
      localsignature=signature;
    }

    //so now have localsignature
    return {
      address:addressin,
      signature:localsignature
    };

  }catch(ex){
    return {
      address:'',
      signature:''
    };
  }

}