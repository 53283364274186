
import {autourl,BASE_URI
  ,ONE_TOKEN,TOKEN_GALLERY,INTERACTIVE
  ,MINT_TOKEN,MY_TOKENS,USER_TOKENS
  ,RANDOM_INTERACTIVE,RANDOM_TOKEN,EXTRA_INTERACTIVE
  } from 'lib/lib.js'


  const Mint = [
    {
      type: 'item',
      disable: false,
      text: MINT_TOKEN,
    },
    {
      type: 'item',
      disable: false,
      text: MY_TOKENS,
    }
  ];

  const Explore = [
/*     {
      type: 'item',
      disable: false,
      text: TOKEN_GALLERY,
    }, */
    {
      type: 'item',
      disable: false,
      text: MY_TOKENS,
    }
  ];

const FeelingLucky = [
  {
    type: 'item',
    disable: false,
    text: RANDOM_INTERACTIVE
  },
  {
    type: 'item',
    disable: false,
    text: RANDOM_TOKEN
  }
  /* ,{
    type: 'item',
    disable: false,
    text: EXTRA_INTERACTIVE
  } */
];
const View = [
  {
    type: 'item',
    disable: false,
    text: 'Contract',
  },
];
const Help = [
  {
    type: 'item',
    disable: false,
    text: 'Help Topics',
  },
  {
    type: 'item',
    disable:false,
    text: 'About',
  },
];
export default {FeelingLucky, Explore , View, Help,Mint };
