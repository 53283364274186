import React, { setGlobal ,useGlobal} from 'reactn';

import ReactDOM from 'react-dom';


import './index.css';
import 'assets/clear.css';
import 'assets/font.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { reducers } from './reducers';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';



import Web3Modal from "web3modal";
import { ethers } from 'ethers'
import MultiverseVirtualMachine from 'artifacts/contracts/MultiverseVirtualMachine.sol/MultiverseVirtualMachine.json'

import {CONTRACT_ADDR} from 'lib/lib.js'
import './index.css';

const store = createStore(reducers, compose(applyMiddleware(thunk)));



//------------------------------------------wallet setup

const contractAddress = CONTRACT_ADDR

const providerOptions = {
  /* See Provider Options Section */
};



var defaultDappState = {
  address:window.ethereum?window.ethereum.selectedAddress:null,
  provider:null,
  contract:null,
  extradata:"",
  chainId:null,
  web3Modal:new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
  }),
  extradata2:1,
  externalstatread:false,
  hastoken:false
};


console.log("setting default app state");
console.log(defaultDappState);

//set provider if exists
if(window.ethereum!=null){
  console.log("metamask detected");
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const contract = new ethers.Contract(contractAddress, MultiverseVirtualMachine.abi, provider)
  console.log("setup contract and provider")
  defaultDappState.provider=provider;
  defaultDappState.contract=contract;
}

console.log("default app state",defaultDappState);
setGlobal(defaultDappState);


// Note that this event is emitted on page load.
// If the array of accounts is non-empty, you're already
// connected.


//------------------------------------------wallet setup


ReactDOM.render(
    <App />
  , document.getElementById('root'));

serviceWorker.register();
