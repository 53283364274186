import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';

import { ethers } from 'ethers';
import MultiverseVirtualMachine from 'artifacts/contracts/MultiverseVirtualMachine.sol/MultiverseVirtualMachine.json';

import styled from 'styled-components';

import { autourl, CONTRACT_ADDR, correctChain, chainString } from 'lib/lib.js';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import { 
  styleReset,
  Button,
  TextField,
  WindowHeader,
  WindowContent,
  Fieldset,
  Panel,
} from 'react95';

const contractAddress = CONTRACT_ADDR;

export default function Metamask({ onClose }) {
  //#region function

  const [dappState, setDappState] = useGlobal(); //entire dappstate
  console.log('printing dapp state');
  console.log(dappState);

  if (dappState.address != null) {
    console.log('detected dapp address in metamask');
    console.log(dappState.address);
  }
  if (dappState.provider != null) {
    console.log('detected dapp provider in metamask');
    console.log(dappState.provider);
    console.log(dappState.contract);
  }

  const web3Modal = dappState.web3Modal;
  const provider = dappState.provider;
  const contract = dappState.contract;
  const address = dappState.address;

  // For now, 'eth_accounts' will continue to always return an array
  function ConnectToWallet() {
    if(window.ethereum!=null){
      
    window.ethereum.request({ method: 'eth_requestAccounts' }, addr => {
      console.log('eth requested account success');
      console.log('returned address:', addr);

      //setup everything

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        contractAddress,
        MultiverseVirtualMachine.abi,
        provider,
      );

      console.log('set global address/provider/contract');
      setGlobal({ address: addr, provider: provider, contract: contract });
    });
  }else{
    console.log('no ethereum detected');
  }

  }
  // For now, 'eth_accounts' will continue to always return an array
  async function TestContract() {
    if (typeof window.ethereum !== 'undefined') {
      console.log('testing smart contract');

      try {
        const data = await contract.greet();
        console.log('Greeting Data: ', data);
        setGlobal({ extradata: data });
      } catch (err) {
        console.log('Greeting Error: ', err);
      }
    } else {
      console.log('ethereum api not detected');
    }
  }
  //#endregion

  return (
    <ThemeProvider theme={original}>
      <ADiv>
        <Panel
        className='panel'
          variant="well"
          style={{
            background: '#c6c6c6',
            height: '100%',
            width:'100%'
          }}
        >
          <fieldset className="fieldset">
            <legend>The Beginning</legend>
            Here's where the journey begins, connect to experience the multiverse virtual machine.
          </fieldset>

          <Button
            className="connectButton"
            disabled={dappState.address != null}
            onClick={ConnectToWallet}
          >
            {dappState.address != null ? 'Connected' : 'Connect Metamask'}
          </Button>

          <fieldset className="fieldset">
            <legend>Traveller Account</legend>
            {dappState.address != null ? dappState.address : 'Not Detected'}
          </fieldset>

          <fieldset className="fieldset">
            <legend>Network</legend>

            {correctChain(dappState.chainId) && chainString(dappState.chainId)}
            {!correctChain(dappState.chainId) && (
              <span style={{color:'blue'}}>&nbsp;Please connect to the Ethereum Mainchain</span>
            )}
          </fieldset>
        </Panel>
      </ADiv>
    </ThemeProvider>
  );
}

const ADiv = styled.div`

margin-top:3px;

.panel{
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-left:10px;
  padding-right:10px;
  
}
  
  .np__toolbar {
    
    margin-top: 4px;
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }

  .fieldset {
    margin: 10px;
    padding: 10px;
    font-size: 10px;
    width: 100%;
  }

  .fieldset > legend {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    background: #c6c6c6 !important;
  }

  .connectButton {
    width: 200px;
  }
`;
