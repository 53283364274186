import React, { Fragment, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import textimage from 'assets/windowsIcons/textimage.PNG';


import {PREINTROTIME,INTROTIME,PRELAUNCHTIME
  
} from 'lib/lib.js'

function StartVideo(props) {
  const [videoDisplay, setVideoDisplay] = useState('block');

  function makeVideoHidde() {
    setTimeout(() => {
      setVideoDisplay('none');
    }, PREINTROTIME);
  }
  useEffect(() => {
    makeVideoHidde();
  }, []);
  return (
    <Container
      style={{
        width: '100vw',
        height: '100vh',
        display: videoDisplay,
        position: 'relative',
      }}
    >
      <div className="fakeBody">

      {PRELAUNCHTIME != PREINTROTIME && (
<div>
      <div >
      <img src={textimage} style={{maxWidth:'200px'}}/>
      </div>

</div>)}
        <span className="pageone"> </span> 

        <div className=""> 
          <span className="pagetwo"></span>
        </div>

        <div className="pagethree">
          
          <div className="three-main three-one">
            Loading... <br />
            <span className="useless"></span>
          </div>

          <div className="three-main three-two">
            Initializing Multiverse Generator
            <br /> 3.12654 sd 0:0:0:0: km Assuming driver cache: write through
            <br />
            <span className="useless2"></span>
          </div>

          <div className="three-main three-three">
            INIT: Version 1.001 Booting
            <br />
            
            {PRELAUNCHTIME == PREINTROTIME && (
              <div>
                <br />{' '}
                <span className="three-span">
                  MULTIVERSEVM WILL LAUNCH SOON...
                </span>
                <br />
                <br />
                <iframe
                  width="300"
                  height="300"
                  src="https://www.youtube.com/embed/wpvNwkuFq4Q?rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <br/>
                <a href="https://twitter.com/0xRKY">FOLLOW VM ADMIN FOR LATEST PROGRESS</a>
                <br />
              </div>
            )}
            <span className="three-span"> info </span> Using Multiverse OS to load drivers<br />
            <span className="three-span"> ok </span> starting ethereum network connector
            <br />
            <span className="useless3"></span>

          </div>

          <div className="three-main three-four">
            .... Synchronising blockchain timeline... blocksize UNKNOWN
            0000:00:03.7: Hot bus controller not enabled! 
            <br />
            <span>done.</span>
            <br />
            Waiting for system to be fully operational
            <br />
            <span>Multiverse explorer initialized....</span>
            <br />
            <span className="useless4"></span>
          </div>

          <div className="three-main three-five">
            done.
            <br /> <span className="three-span"> ok </span> Setting Multiverse Connector... done.
            <br />
            <span className="useless5"></span>
          </div>

          <div className="three-main three-six">
            <span className="three-span"> ok </span> Booting Multiverse Portal Sequence...
            <br />
            .... Checking root file system... Detecting blockchain metadata:
            clean, metaverse connection detected, 1st CONTACT MADE: 🄻🄾🄾🅃
            <br />
            done.
            <br />
            <span className="useless6"></span>
          </div>

          <div className="three-main three-seven">
            <span className="three-span"> ok </span> Initialized Metaverse 'LOOT' CONNECTOR
            <br />
            PINGING METAVERSE 'ＰｕⓃķ𝕤', '𝐀ℙ𝔼S ♖', 'CͨAͣᴛⷮ', 'ρ𝐄𝐍Ⓖ𝕌𝕀𝓷𝕊', 'L͚͍̠I̡͍͉O̪̙̺N͚̻̻'...
            <br /> <span className="three-span"> RECEIVED RESPONSE </span> Arriving in 1000000
            BLOCKS.....{' '}

            <br />
            <span className="useless7"></span>
          </div>
        </div>

        <div className="pagefour">
          <div>
            <span className="four-one"></span>
          </div>{' '}
          <br />
          <br />
          <div> 
            <p>
              <span id="time" className="four-span">
                {' '}
              </span>
              <span className="four-two"></span>
            </p>
          </div>
          <div>
            <p>
              <span id="time2" className="four-span">
                {' '}
              </span>
              <span className="four-three"></span>
            </p>
          </div>
          <div>
            <p>
              <span id="time3" className="four-span">
                {' '}
              </span>
              <span className="four-four"></span>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

const SpecialImg=styled.img



const Container = styled.div`
  .fakeBody {
    font-family: Monaco, Consolas, 'Lucida Console', monospace;
    background-color: black;
    /*	color: #1EFF00;*/
    /*	color: rgb(0,140,35);	*/
    color: #eef4fa;
    padding: 20px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .typed-cursor {
    background: #eef4fa;
    opacity: 1;
    animation: blink 0.7s infinite;
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .three-main {
    display: none;
  }

  .three-span {
    /*	color: rgb(0,140,35);*/
    color: #1eff00;
  }

  .four-one {
    color: #1eff00;
  }

  .four-main {
    display: none;
  }

  .four-span {
    color: #1eff00;
  }

  #time,
  #time2,
  #time3 {
    display: none;
    font-weight: 400;
  }
  .coverAll {
    height: 100vh;
    width: 100vw;
  }
  .positionAb {
    position: absolute;
    top: 0;
    left: 0;
  }
`;


StartVideo.propTypes = {};

export default StartVideo;







