//import React, { useState } from 'react';
import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';

import styled from 'styled-components';

import { WindowDropDowns } from 'components';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import {
  autourl,
  getRandomInt,
  BASE_URI,
  ONE_TOKEN,
  TOKEN_GALLERY,
  INTERACTIVE,
  MINT_TOKEN,
  MY_TOKENS,
  USER_TOKENS,
  RANDOM_INTERACTIVE,
  RANDOM_TOKEN,CORRECT_NETWORK_WINDOW,correctChain,HOME_WINDOW
} from 'lib/lib.js';

import {
  styleReset,
  Button,
  TextField,
  WindowHeader,
  WindowContent,
  List,
  ListItem,
  Divider,
  Cutout,
  Toolbar,
  Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeadCell,
  TableDataCell,
  Window,
} from 'react95';

import dropDownData from './dropDownData';

import axios from 'axios';

import Home from './Home';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
  ${styleReset}
`;
export default function Help({ onClose,onClickMenuItem }) {
  const [dappState, setDappState] = useGlobal(); //entire dappstate

  const contract = dappState.contract;
  const address = dappState.address;

  console.log('dapp state', dappState);

  //const [view, setView] = useState(MINT_TOKEN);
  //const [view, setView] = useState(TOKEN_GALLERY);
 // const [view, setView] = useState(ONE_TOKEN);
  const [view, setView] = useState(HOME_WINDOW);

  function onClickOptionItem(item) {

    switch (item) {
      case HOME_WINDOW:
        setView(HOME_WINDOW);
        break;
   
      default:
    }
  }
/* 
  function TestClick() {
    onClickMenuItem('Travel Log');
  } */

  return (
    <ThemeProvider theme={original}>
      <Div>
        <section className="np__toolbar" >
          <WindowDropDowns
            items={dropDownData}
            onClickItem={onClickOptionItem}
          />
        </section>

        <Panel
          variant="well"
          style={{
            padding: '1rem',
            overflow: 'auto',
            background: 'white',
            margin:'2px',
            justifyContent:'center',
            overflow:'auto',
            display: 'flex',
            alignItems:'flex-start',
            height:'100%'
          }}
        >

            {view === HOME_WINDOW && (
              <div>
                <Home
                ></Home>
              </div>
            )}



        </Panel>
      </Div>
    </ThemeProvider>
  );
}

//background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);

const Div = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: stretch;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
    
  }
background: '#c6c6c6'
`;

const StyledTextarea = styled.textarea`
  flex: auto;
  outline: none;
  font-family: 'Lucida Console', monospace;
  font-size: 13px;
  line-height: 14px;
  resize: none;
  padding: 2px;
  ${props => (props.wordWrap ? '' : 'white-space: nowrap; overflow-x: scroll;')}
  overflow-y: scroll;
  border: 1px solid #96abff;
`;
