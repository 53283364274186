import InternetExplorer from './InternetExplorer';
import Minesweeper from './Minesweeper';
import ErrorBox from './ErrorBox';
import MyComputer from './MyComputer';
import Notepad from './Notepad';
import Metamask from './Metamask';
import ArtNotWar from './ArtNotWar';
import Gallery from './Gallery';
import Winamp from './Winamp';
import Paint from './Paint';
import EasterEgg from './EasterEgg';
import TwitterBot from './TwitterBot';
import Help from './Help';
import iePaper from 'assets/windowsIcons/ie-paper.png';
import ie from 'assets/windowsIcons/ie.png';
import mine from 'assets/minesweeper/mine-icon.png';
import error from 'assets/windowsIcons/897(16x16).png';
import computer from 'assets/windowsIcons/676(16x16).png';
import computerLarge from 'assets/windowsIcons/676(32x32).png';
import notepad from 'assets/windowsIcons/327(16x16).png';
import notepadLarge from 'assets/windowsIcons/327(32x32).png';
import metamask from 'assets/windowsIcons/metamask.png';
import metamaskLarge from 'assets/windowsIcons/metamask.png';
import winamp from 'assets/windowsIcons/winamp.png';
import paintLarge from 'assets/windowsIcons/680(32x32).png';
import paint from 'assets/windowsIcons/680(16x16).png';
import question from 'assets/windowsIcons/question.png';
import wolfLarge from 'assets/windowsIcons/wolf.gif';
import wolf from 'assets/windowsIcons/wolf.gif';
import twitterBotLarge from 'assets/windowsIcons/twitter.png';
import twitterBot from 'assets/windowsIcons/twitter.png';


import gallery from 'assets/windowsIcons/gallery.png';
import galleryLarge from 'assets/windowsIcons/galleryLarge.png';

const gen = () => {
  let id = -1;
  return () => {
    id += 1;
    return id;
  };
};
const genId = gen();
const genIndex = gen();

//this is for the initial app state

const largew=900;
const largeh=700;
const varw=50;
const varh=50;


export const defaultAppState = [
/* 
 
 {
    component: MyComputer,
    header: {
      title: 'VM Status',
      icon: gallery,
    },
    defaultSize: {
      width: 900,
      height: 700,
    },
    defaultOffset: {
      x: 250,
      y: 25,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    id: genId(),
    zIndex: genIndex(),
  }
   */
    {
    component: Gallery,
    header: {
      title: 'Multiverse VM',
      icon: gallery,
    },
    defaultSize: {
      width: largew,
      height: largeh
    },
    defaultOffset: {
      x: 250,
      y: 8,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    id: genId(),
    zIndex: genIndex(),
  }
  , 
 {
  component: Metamask,
  header: {
    title: 'Metamask Wallet',
    icon: metamask,
  },
  defaultSize: {
    width: 400,
    height:291
  },
  defaultOffset: {
    x: 60,
    y: 100
  },
  resizable: false,
  minimized: false,
  maximized: window.innerWidth < 600,
  id: genId(),
  zIndex: genIndex(),
},
 {
  header: {
    icon: computer,
    title: 'VM Status',
  },
  component: MyComputer,
  defaultSize: {
    width: 572,
    height: largeh-varh
  },
  defaultOffset: {
    x: 480,
    y: 50,
  },
  resizable: true,
  minimized: false,
  maximized: window.innerWidth < 800,
  multiInstance: false,
}
,
{
  component: ArtNotWar,
  header: {
    title: "XPERIENCE 𝕀𝕀",
    icon: computer,
  },
  defaultSize: {
    width: 600,
    height: 500
  },
  defaultOffset: {
    x: 400,
    y: 40,
  },
  resizable: true,
  minimized: false,
  maximized: window.innerWidth < 800,
  id: genId(),
  zIndex: genIndex(),
}
];


export const defaultIconState = [
  {
    id: 0,
    icon: metamaskLarge,
    title: 'Metamask',
    component: Metamask,
    isFocus: false,
  },
  {
    id: 1,
    icon: galleryLarge,
    title: 'Multiverse VM',
    component: Gallery,
    isFocus: false,
  },
  {
    id: 2,
    icon: ie,
    title: 'Search',
    component: InternetExplorer,
    isFocus: false,
  },
  {
    id: 3,
    icon: mine,
    title: 'Minesweeper',
    component: Minesweeper,
    isFocus: false,
  },
  {
    id: 4,
    icon: computerLarge,
    title: 'VM Status',
    component: MyComputer,
    isFocus: false,
  },
  {
    id: 5,
    icon: wolfLarge,
    title: 'Wolf 3D (Earth sim)',
    component: EasterEgg,
    isFocus: false,
  },
  {
    id: 6,
    icon: notepadLarge,
    title: 'Travel Log',
    component: Notepad,
    isFocus: false,
  },
  {
    id: 7,
    icon: winamp,
    title: 'Winamp (Earth sim)',
    component: Winamp,
    isFocus: false,
  },
  {
    id: 8,
    icon: paintLarge,
    title: 'Paint (Earth sim)',
    component: Paint,
    isFocus: false,
  },
  {
    id: 9,
    icon: twitterBotLarge,
    title: 'VM Bot (Earth receiver)',
    component: TwitterBot,
    isFocus: false,
  }
];

export const appSettings = {
  'Search': {
    header: {
      icon: iePaper,
      title: 'Search',
    },
    component: InternetExplorer,
    defaultSize: {
      width: largew,
      height: largeh
    },
    defaultOffset: {
      x: 140,
      y: 30,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Minesweeper: {
    header: {
      icon: mine,
      title: 'Minesweeper',
    },
    component: Minesweeper,
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 190,
      y: 180,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Error: {
    header: {
      icon: error,
      title: 'C:\\',
      buttons: ['close'],
      noFooterWindow: true,
    },
    component: ErrorBox,
    defaultSize: {
      width: 380,
      height: 0,
    },
    defaultOffset: {
      x: window.innerWidth / 2 - 190,
      y: window.innerHeight / 2 - 60,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  'VM Status': {
    header: {
      icon: computer,
      title: 'VM Status',
    },
    component: MyComputer,
    defaultSize: {
      width: 572,
      height: largeh-varh
    },
    defaultOffset: {
      x: 260,
      y: 50,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: false,
  },
  Help: {
    header: {
      icon: question,
      title: 'Help',
    },
    component: Help,
    defaultSize: {
      width: 660,
      height: 400,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: false,
  }
  ,
  Notepad: {
    header: {
      icon: notepad,
      title: 'Travel Log',
    },
    component: Notepad,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Metamask: {
    header: {
      icon: metamask,
      title: 'Metamask Wallet',
    },
    component: Metamask,
    defaultSize: {
      width: 400,
      height:291
    },
    defaultOffset: {
      x: 80,
      y: 100,
    },
    maximized: window.innerWidth < 400,
    resizable: false,
    minimized: false,
    multiInstance: false,
  },
  Gallery: {
    header: {
      icon: gallery,
      title: 'Multiverse VM',
    },
    component: Gallery,
    defaultSize: {
      width: largew,
      height: largeh
    },
    defaultOffset: {
      x: 250,
      y: 8,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Winamp: {
    header: {
      icon: winamp,
      title: 'Winamp (Earth sim)',
      invisible: true,
    },
    component: Winamp,
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  Paint: {
    header: {
      icon: paint,
      title: 'Untitled - Paint',
    },
    component: Paint,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 280,
      y: 70,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  EasterEgg: {
    header: {
      icon: wolf,
      title: 'Wolf 3D',
    },
    component: EasterEgg,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 280,
      y: 70,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: false
  },
  TwitterBot: {
    header: {
      icon: twitterBot,
      title: 'VM Bot (Earth receiver)',
    },
    component: TwitterBot,
    defaultSize: {
      width: 400,
      height: 550,
    },
    defaultOffset: {
      x: 300,
      y: 80,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: false
  }
};


export const defaultDappState = {
  Wallet: {
    address:"wallet address",
    connected:false
  },
  Dapp: {
    
  },
  extradata:""
};


export { InternetExplorer, Minesweeper, ErrorBox, MyComputer, Gallery,Notepad, Winamp,Metamask };
