import React, { setGlobal, useGlobal } from 'reactn';

import WinXP from 'WinXP';

//smart contracts


const App = () => {
  //smart contracts
  const [dappState, setDappState] = useGlobal();

  async function loadAllEthProperties() {

    if (window.ethereum != null) {
      var ethereum = window.ethereum;

      // For now, 'eth_accounts' will continue to always return an array
      function handleAccountsChanged(accounts) {
        console.log('js event: handle account changed: ', accounts);

        var currentaccount = null;
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log('Please connect to MetaMask.');
        } else if (accounts.length > 0) {
          console.log('you have an account connected', accounts[0]);
          currentaccount = accounts[0];
          // Do any other work!
        }

        setGlobal({ address: currentaccount });
      }

      // For now, 'eth_accounts' will continue to always return an array
      function handleChainChanged(_chainId) {
        console.log('chain changed');

        setGlobal({ chainId: _chainId });
        window.location.reload();
      }

      // For now, 'eth_accounts' will continue to always return an array
      function setupChain(_chainId) {
        console.log('chain setup');

        setGlobal({ chainId: _chainId });
      }

      //get chainid
      const chainId = await ethereum.request({ method: 'eth_chainId' });
      setupChain(chainId);

      window.ethereum.on('chainChanged', handleChainChanged);

      //get account if exists

      let currentAccount = null;
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then(handleAccountsChanged)
        .catch(err => {
          // Some unexpected error.
          // For backwards compatibility reasons, if no accounts are available,
          // eth_accounts will return an empty array.
          console.error(err);
        });

      //get accounts maybe (emitted on page load)
      window.ethereum.on('accountsChanged', handleAccountsChanged);

      //request account here if ethereum is not null
    }
  }

  loadAllEthProperties();

  return <WinXP />;
};

export default App;
