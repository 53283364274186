import styled from 'styled-components';
import axios from 'axios';
import { autourl, animationurl } from 'lib/lib.js';

import { createGlobalStyle, ThemeProvider } from 'styled-components';

import original from 'react95/dist/themes/original';
// original Windows95 font (optionally)
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import {
  MAX_TOKEN,
  getRandomInt,
  BASE_URI,
  ONE_TOKEN,
  TOKEN_GALLERY,
  INTERACTIVE,
  MINT_TOKEN,
  MY_TOKENS,
  USER_TOKENS,
  RANDOM_INTERACTIVE,
  RANDOM_TOKEN,CORRECT_NETWORK_WINDOW,correctChain,EXTERNAL_LINK ,
  MVERSE_TOKEN,CONTRACT_ADDR,MINT_PRICE_DEFAULT,MORE_URL
} from 'lib/lib.js';

import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';

import {
  styleReset,
  Button,
  TextField,
  WindowHeader,
  WindowContent,
  List,
  ListItem,
  Divider,
  Cutout,
  Toolbar,
  Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeadCell,
  TableDataCell,
  Window,
} from 'react95';

const Home = () => {

  const [dappState, setDappState] = useGlobal(); //entire dappstate

  const contract = dappState.contract;
  const address = dappState.address;

  const [tokensLeft, SetTokensLeft] = useState(-1);

  //fetches all tokens that are minted at the moment
  async function fetchTokensLeft() {
    var inputtokens = [];

    if (typeof window.ethereum !== 'undefined') {
      console.log('fetching tokens left');

      try {
        const data = await contract.tokenCounter(); //the maximum
        //if 8000 then maximum

        SetTokensLeft(MAX_TOKEN-data);

      } catch (err) {
        console.log('fetch all token error: ', err);
      }
    } else {
      console.log('ethereum api not detected');
    }

    return inputtokens;
  }

  let specialink="specialink";


  useEffect(() => {
    fetchTokensLeft();
  }, [address]);

  return (
    <ThemeProvider theme={original}>
      <Div>
        <h2>About </h2>
        <Divider />
        <div>
          Multiverse VM, a dynamic NFT with fun mechanisms to <span className='bold'> bring your NFT into the metaverse.</span> 
          <br/>
          A unique dynamic NFT design, each and every NFT maintains  <span className='bold'>links
          to other NFT tokens</span> generated upon the creation of the
          multiverse, and additional links/connection portals are generated via a combination of
          the owner address and the token seed (indicated in blue), more fun experiments can be found <a className={specialink} href="https://www.xenoinfinity.com">here</a>.
        </div>

        <h2>Designed for the Multiverse</h2>
        <Divider />

        <div>
          The multiverse VM is designed so owners can bring their existing NFTs in the multiverse, with more fun experiences that can be found <a className={specialink} href="https://www.xenoinfinity.com">here</a>, with initial support for Loot, Temporal Loot and Synthetic Loot meaning any holder can get the full experience.
          <br/>
          <br/>
          Tokens are designed to support extra URIs for each token holder, this allows for more extensibility, while the original token URI metadata stays persistent (e.g. basic tokenuri for core metadata, extra metadata uri slots can be used to support fun experiences such as the 'Simulation Exploration', latest OS version is found <a className={specialink} href={MORE_URL}>here</a>)
          <br/>
          <br/>
          {MVERSE_TOKEN} Mverse tokens are a subset for special usecases (e.g. S͛рⷬaͣcͨeͤ oͦf нⷩoͦnoͦrͬaͣrͬy etc), simulations minted in multiverse vm do have a probability of establishing links to Mverse tokens upon creation as well.  
        </div>

        <h2>Interacting with Simulations</h2>
        <Divider />

        <div>
          Summon: Click on a Loot bag and click 'Summon' to bring the adventurer into the simulation<br/>
          W/S: Adjust Light Brightness Slightly<br/>
          A/D: Adjust Light Direction<br/>
          Z: Toggle Shadow Modes<br/>
          X: Auto Rotate Light 360 Degrees<br/>
          U: Resume To Guardian<br/>
          Mouse: Rotate Simulation Environment<br/>
          T: Toggle Universe Filters if Exists<br/>
        </div>

        <h2>About Multiverse VM</h2>
        <Divider />

        <div>
          The Multiverse VM -- began as an experiment, and evolved into an interactive art project that explores the possibilities of the ethereum blockchain and the metaverse experience.
          <br/>
          <br/>
          'ＰＨΛＳΞ░➋' -- experiments which produced interesting results during the creation process of Multiverse VM, mͫoͦrͬeͤ oͦf ᴛⷮeͤxͯрⷬeͤrͬiͥmͫeͤnᴛⷮs͛ cͨaͣn вⷡeͤ foͦuͧndͩ hͪeͤrͬeͤ - <a className={specialink} href={MORE_URL}>🌀</a>
          <br/>
          <br/>
          All Dynamic NFTs minted are licensed under the NFT License @ https://www.nftlicense.org/, token uri metadata includes both decentralized versions and hosted versions of the NFT data, metadata is initially setup on a centralized server and will be locked into IPFS 2 months after the initial minting process, this guarantees performance while interacting with the NFT and guarantees persistence of NFT data via IPFS. MultiverseVM NFTs are NOT investment vehicles, they are collectible digital interactive art pieces that also function as Non-Fungible Tokens for you to collect. They were created as art pieces intended for people to enjoy by collecting and playing, not as a financial instrument.
        </div>

        <div>
          <p>
            <strong>Contract address:</strong>
              {CONTRACT_ADDR}
            <br />
            <strong>Name:</strong> MultiverseVM
            <br />
            <strong>Price:</strong> {MINT_PRICE_DEFAULT} ETH<br />
            <strong>Tokens:</strong> {MVERSE_TOKEN+MAX_TOKEN}<br />
            <strong>Sold Out:</strong> {tokensLeft==0?'Yes':'No'}<br />
            <strong>Mverse tokens:</strong> {MVERSE_TOKEN}<br />
          </p>
        </div>

        <h2>Links</h2>
        <Divider />

        <div>
            <a href="https://etherscan.io/token/0xD5A73977e6eFfC252A7f1AF4548BA11fF7187616">Etherscan</a><br/>
            <a href="https://opensea.io/collection/multiversevm">Opensea</a><br/>
            <a href="https://discord.gg/b7ZcEKBEsu">Discord</a><br/>
            <a href="https://twitter.com/MultiverseVM">Multiverse VM Twitter</a> 
            <br/><br/>
            <a href="https://twitter.com/0xRKY">VM Admin @EARTH/ (SIM D5)</a> &nbsp;&nbsp;
            <a href="#">V͔̻̼M͔̪̺ A̢͉̻d̼̼͖m̘̫͎i̢̙͕n̡̻̺ @U̸̟͓̔͝͝N̵͔̟̦͛͐̚K̸͎͙̪̐̽͝N̸̠͓̻͒̽͘O̵̡͔͎͊͘͝W̵͍͓͉̾̓͝N̸͇͚̟͌̚̕  (SIM E6)</a> 
            <br/>
            <br/>
        </div>
      </Div>
    </ThemeProvider>
  );
};

const Div = styled.div`

.specialink{
 color:black;
}

.bold{
  font-weight:bold;
}

  background: white;
  height: 100%;
  width: 100%;
  font-size: 11px;
  .item {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    font-size: 12px;
    width: 100%;
  }

  .item video {
    margin-bottom: 5px;
    object-fit: cover;
    width: 90%;
    height: 85%;
  }

  div {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 5px;
  }
`;

export default Home;
